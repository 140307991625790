@import '../../style/App.scss';




.modal {
  display: flex;
  // justify-content: center;
  align-items: center;
  width: 90%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  color: white;
  margin: 0 5%;
  padding-top: 20px;



  .backdrop {
    background-color: $black-color;
    opacity: 0.97;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    display: block;
    cursor: pointer;
  }
  

  .reveal_fade {
    background-color: white;
    height: 70%;
  }

  div {
    z-index: 400;
    width: 100%;
    height: 75vh;

  
    .sucess__modal {
      background-color: rgb(0, 0, 0);
      z-index: 500;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      position: relative;
      height: 100%;
                
      .modal_contents {
        // background-color: black;
        padding-left: 10px;
        height: 20%;
        min-height: 70px;
        // height: 25vh;
        width: 100%;
        
        .row {
          height: 45%;
          // font-size: 90%;
          // height: 40px;
          // max-height: 20px;
          // tex
        color: whitesmoke;

        }
      }
      .player_wrapper {
        // background-color: white;
        // margin: 0 auto;
        height: 80%;
        // height: 25vh;
        display: flex;
        justify-content: center;
      }

      }
    }
  }

.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  position:absolute;
  right: 0;
  top: 0
}
.close-button:hover {
  color: white;
  background-color: $main-color;
}


.modal__player {
  height: 80%;
  width: 90%;
  margin: auto;
}

// @media screen and (max-width: 400px) {
//   .modal_contents {
//     // display: inline-block;
//     width: 50%;
//   }

//   .player_wrapper {
//     // display: inline-block;
//     // width: 50%;
//     background-color: purple;
//   }
// }


@import '../../style/App.scss';

#portfolio {
  padding: 20px 0 40px 0;
  min-height: 60vh;

  .portfolio__nav {
    margin: 2px auto;
    ul {
      display: flex;
      li {
        padding: 5px 15px;
        margin-right: 10px;
        cursor: pointer;
        transition: 0.3s ease;
      }
      li:hover {
        background-color: $main-color;
      }
    }
    .portfolio__nav-active {
      background-color: $main-color;
    }
  }
  .portfolio__filter {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 50px;
    background-attachment: fixed;
    max-width: 200px;
    float: right;
    position: relative;
    padding: 8px 10px;
    

    p {
      padding-right: 10px;
    }

    .portfolio__filter-menu {
      background-color: $light-color;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 100px;
      text-align: center;
      border-radius: 5px;
      z-index: 999;
      
      p {
        padding: 8px 0;
        margin: 2px 0;
        transition: 0.3s ease;
      }
      p:hover {
        background-color: $main-color;
      }
    }
  }

  .desc {
    padding-bottom: 70x;
    padding-top: 70x;
    width: 100%; /* set width to 100% by default */
    max-width: 750px;
    text-align: center;
    margin: 0 auto;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -2px; /* gutter size offset */
  width: auto;
}
.mint__gallery {
  // padding-left: 2px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.mint__gallery > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 2px;
  margin: 0px auto;
}

@media screen and (max-width: 767px) {
  #portfolio {
    .portfolio__nav {
      ul {
        justify-content: center;
      }
    }
    .portfolio__filter {
      justify-content: center;
      max-width: 200px;
      margin: 30px auto;
      float: none;

    }
  }
}

@media screen and (max-width: 400px) {
  #portfolio {
    .portfolio__nav {
      ul {
        li {
          padding: 5px 5px;
          margin-right: 5px;
        }

      }
    }
  }
}

  @import '../../../style/App.scss';
  

  .portfolio__box {
    position: relative;
    // margin: 2px auto;
    cursor: pointer;
    


    img {
      width: 100%;
      height: auto;
      position: relative;
    }
    

    .portfolio__hover-info {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(33, 33, 33, 0.8));
      
      p {
        text-transform: uppercase;
        color: rgb(184, 184, 184);
        text-align: left;
        bottom: 0;
        position: absolute;
        padding: 5px;
      }
    }
    .portfolio__hover-info:hover {
      opacity: 1;
      background-color: rgba($main-color, 0.8);
      transition: 0.4s ease;


    }
  }

  .modal__text {
    display: flex;
    p{
      font-size: 1vw;
    }
  }

  .modal__player {
    height: 80%;
    width: 90%;
    margin: auto;
  }

.ontop {
  position: absolute;
  
}
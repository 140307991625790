@import '../../style/App.scss';



.header {
  width: 100%;
  // height: 5px;

  .header-bar {
    height: 100px;
    display: flex;
    width: 100%;

  }
  
  p {
    color: black;
    // margin-left: 30px;
  }
  img {
    width: 70px;
  }

}

.hero {
  width: 100%;
  padding-bottom: 60px;
  .hero-info {
    padding-top: 60px;
    p {
      padding-top:60px;
      padding-bottom: 50px;
    }
  }

  .hero-image {
    width: 200px;
    margin: 0 auto;
  }
}


@media screen and (max-width: 1080px) {
  .hero {
    padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 990px) {
  .hero {
    .hero-info {
      text-align: center;
    }
  }
}
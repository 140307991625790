@import '../../style/App.scss';

.footer {
  background-color: rgb(32, 32, 32);
  width: 100%;
  min-height: 100px;

  .footer-box {
    display: flex;
    float: right;
    flex-direction: column;
    padding: 20px;

    p {
      color: $white-color;
      text-align: right;
    }
  }
  
  img {
    width: 70px;
  }

  .back-to-top {
    float: right;
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }

  .back-to-top:hover {
    p {
      color: $main-color;
    }
  }
}


